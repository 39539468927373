<template>
    <div class="container">
        <span class="error-404-title">404</span>
        <span class="error-404-subtitle"
            >Page not found.
            <a class="error-404-link" id="home-link" @click="backToHome()"
                >Back to home.</a
            ></span
        >
    </div>
</template>
<script>
export default {
    name: "PageNotFound",
    methods: {
        backToHome() {
            document.getElementById("home-link").classList.add("loading");
            this.$router.push({ name: "Home" });
        },
    },
};
</script>
<style lang="scss">
.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    place-content: center;
    place-items: center;

    .error-404-title {
        font-size: 3rem;
    }

    .error-404-subtitle {
        font-size: 1.5rem;
    }

    .error-404-link {
        color: #02a6e7;
        text-decoration: underline;
        cursor: pointer;

        &:active {
            cursor: wait;
        }
    }

    .error-404-link.loading {
        cursor: wait;
    }
}
</style>
